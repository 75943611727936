import { atom } from 'recoil'
import { ProjectTemplate } from './projectTemplates'

export interface PopularCity {
  city: string
  city_param: string
  competition: string
  cpc: string
  search_volume: string
  state: string
}

export interface TopProject {
  project_template: ProjectTemplate
  icon_url: string
}

export interface AggregateStats {
  avm_25th: number
  avm_avg: number
  avm_75th: number
  potential_value_25th: number
  potential_value_avg: number
  potential_value_75th: number
  buildable_sqft_avg: number
  sale_price_avg: number
}

export interface PublicNeighborhoodTopProject extends TopProject {
  latitude: number
  longitude: number
  icon_marker_url?: string
}

export interface PublicNeighborhood extends AggregateStats {
  id: string
  name: string
  full_name: string
  state: string
  statename: string

  geo: {
    center_latitude: string
    center_longitude: string

    max_latitude: string
    max_longitude: string

    min_latitude: string
    min_longitude: string
  }

  total_properties: number
  percent_for_sale: number
  percent_sold_last_year: number

  top_projects: Array<PublicNeighborhoodTopProject>
}

export const publicNeighborhoodState = atom<PublicNeighborhood | null>({
  key: 'PublicNeighborhoodState',
  default: null,
})
