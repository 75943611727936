import cx from 'classnames'
import React, { FC } from 'react'

interface NearbyProjectsProps {
  className?: string
}

const NearbyProjects: FC<NearbyProjectsProps> = ({ className }) => (
  <svg
    className={cx('nearby-projects', className)}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="nearby-projects" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M12,15 C8.885,15 2.66666667,16.5633333 2.66666667,19.6666667 L2.66666667,22 L21.3333333,22 L21.3333333,19.6666667 C21.3333333,16.5633333 15.115,15 12,15 Z"
        id="body"
        fill="#000000"
        fillRule="nonzero"
      ></path>
      <path
        d="M6.887,8 L17.123,8 C17.447,8 17.711,7.736 17.711,7.412 L17.711,7.388 C17.711,7.064 17.447,6.8 17.123,6.8 L16.799,6.8 C16.799,5.024 15.827,3.5 14.399,2.66 L14.399,3.8 C14.399,4.136 14.135,4.4 13.799,4.4 C13.463,4.4 13.199,4.136 13.199,3.8 L13.199,2.168 C12.815,2.072 12.419,2 11.999,2 C11.579,2 11.183,2.072 10.799,2.168 L10.799,3.8 C10.799,4.136 10.535,4.4 10.199,4.4 C9.863,4.4 9.599,4.136 9.599,3.8 L9.599,2.66 C8.171,3.5 7.199,5.024 7.199,6.8 L6.887,6.8 C6.563,6.8 6.299,7.064 6.299,7.388 L6.299,7.424 C6.299,7.736 6.563,8 6.887,8 Z"
        id="hat"
        fill="#000000"
        fillRule="nonzero"
      ></path>
      <path
        d="M12,13 C14.48,13 16.5466667,11.2933333 17.1466667,9 L6.85333333,9 C7.45333333,11.2933333 9.52,13 12,13 Z"
        id="head"
        fill="#000000"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)

export default NearbyProjects
