import * as React from 'react'
import { useRecoilValue } from 'recoil'
import classNames from 'classnames'

import { publicNeighborhoodState } from 'recoil/publicNeighborhood'

import ImgPlant from 'images/projectPlan/plant.png'

import Cta from 'components/MarketingAndPropertyPages/Cta'

import styles from './styles.module.scss'

interface Props {
  className?: string
}

const ProjectsList: React.FC<Props> = ({ className }) => {
  const publicNeighborhood = useRecoilValue(publicNeighborhoodState)

  return (
    <ul className={classNames(styles.projectsList, className)}>
      {publicNeighborhood?.top_projects.map((project) => (
        <li key={project.project_template.id} className={styles.project}>
          <div className={styles.icon}>
            <img src={project.icon_url || ImgPlant} alt={project.project_template.kind} />
          </div>
          <div className={styles.details}>
            <div className={styles.mainDetails}>
              <h4>{project.project_template.name}</h4>
            </div>
            <Cta id={`project-${project.project_template.kind}`} className={styles.start} href="/users/sign_up">
              {'Sign Up to see Costs'}
            </Cta>
          </div>
        </li>
      ))}
    </ul>
  )
}
export default ProjectsList
