import HomeSearch from 'pages/_serverRendered/HomeSearch'
import Section from 'pages/_serverRendered/PublicProperty/Section'
import React, { FC } from 'react'
import { useRecoilValue } from 'recoil'
import { publicPropertyState } from 'recoil/publicProperty'

import styles from './styles.module.scss'

const ComparableHomesNearby: FC = () => {
  const publicProperty = useRecoilValue(publicPropertyState)

  return (
    <Section className={styles.comparableHomesNearby} title="Comparable Homes Nearby">
      <HomeSearch baseUrl={`/public_properties/nearby/${publicProperty?.attom_id}`} />
    </Section>
  )
}

export default ComparableHomesNearby
