import cx from 'classnames'
import React, { FC } from 'react'

interface NeighborhoodProps {
  className?: string
}

const Neighborhood: FC<NeighborhoodProps> = ({ className }) => (
  <svg
    className={cx('neighborhood', className)}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="neighborhood" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M17,9.17919769 L11.224,5.331 L15.17,2.7 L22,7.25 L22,18 L17,18 L17,9.17919769 Z"
        id="house_2"
        fill="#000000"
        fillRule="nonzero"
      ></path>
      <polygon
        id="house"
        fill="#000000"
        fillRule="nonzero"
        points="8.17 5.7 1 10.48 1 21 6 21 6 13 10 13 10 21 15 21 15 10.25"
      ></polygon>
    </g>
  </svg>
)

export default Neighborhood
