import { blogPostPath, formatDate } from 'pages/_serverRendered/Blog/utils'
import cx from 'classnames'
import { PrismicBlogPostResult } from 'pages/_serverRendered/Blog/types'
import React, { FC } from 'react'
import { RichText } from 'prismic-reactjs'
import styles from '../styles.module.scss'

interface HighlightedBlogPostProps {
  blogPost: PrismicBlogPostResult
}

const HighlightedBlogPost: FC<HighlightedBlogPostProps> = ({ blogPost }) => {
  return (
    <section>
      <article className="tw-relative">
        <figure>
          <a
            href={blogPostPath(blogPost)}
            className="tw-block tw-aspect-w-16 tw-aspect-h-9 tw-rounded-sm tw-shadow tw-overflow-hidden"
          >
            {blogPost.data.image.url && (
              <img
                src={blogPost.data.image.url}
                alt={blogPost.data.image.alt || ''}
                width={blogPost.data.image.dimensions?.width}
                height={blogPost.data.image.dimensions?.height}
                className="tw-object-cover"
              />
            )}
          </a>
        </figure>
        <div
          className={cx(
            'lg:tw-absolute lg:tw-left-0 lg:tw--translate-y-1/2 tw-bg-white tw-p-10',
            styles.firstPostContent
          )}
        >
          {blogPost.data.category1?.data && (
            <div className="tw-uppercase tw-text-gray-700">{blogPost.data.category1.data.display_name}</div>
          )}
          <h2 className="tw-font-medium tw-text-2xl tw-my-2 tw-p-0">
            <a className="hover:tw-text-base-charcoal" href={blogPostPath(blogPost)}>
              {blogPost.data.title[0].text}
            </a>
          </h2>
          <div className="tw-font-sans-optima">
            <RichText render={blogPost.data.excerpt} />
          </div>
          <div className="tw-mt-4 tw-text-gray-700 tw-italic tw-font-sans-optima">
            <span>{'By '}</span>
            <span>{blogPost.data.author}</span>
            <span>{' on '}</span>
            <time dateTime={blogPost.data.date}>{formatDate(blogPost.data.date)}</time>
          </div>
          <a
            className="tw-mt-4 tw-bg-moss tw-inline-flex tw-items-center tw-justify-center tw-text-white tw-py-2 tw-px-4 tw-rounded hover:tw-text-white"
            href={blogPostPath(blogPost)}
          >
            {'Read more'}
          </a>
        </div>
      </article>
    </section>
  )
}

export default HighlightedBlogPost
