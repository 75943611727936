import React, { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

import styles from './Card.module.scss'

export interface CardProps {
  className?: string
}

const Card: FC<PropsWithChildren<CardProps>> = ({ children, className }) => (
  <div className={classNames(styles.card, className)}>{children}</div>
)

export default Card
