import React, { FC } from 'react'

interface Props {
  className?: string
}

const DropdownArrow: FC<Props> = ({ className }) => (
  <svg
    width="12px"
    height="8px"
    viewBox="0 0 12 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <polygon
      fill="currentColor"
      fillRule="nonzero"
      transform="translate(-6, -8)"
      points="7.41 8.59 12 13.17 16.59 8.59 18 10 12 16 6 10"
    ></polygon>
  </svg>
)

export default DropdownArrow
