import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'

import cx from 'classnames'
import DropdownArrow from 'svgs/dropdown-arrow'
import Sparkle from 'components/Sparkle'

import styles from './styles.module.scss'
import HomeSearchListings, { HomeSearchResultItem } from 'pages/_serverRendered/HomeSearch/HomeSearchListings'

const MAX_SOLD_YEARS_TO_SHOW = 50
const DEFAULT_PAGE_SIZE = 10
const MAX_SHOW_PAGES = 5

interface Props {
  baseUrl: string
  className?: string
  columns?: 2 | 4
  pageSize?: number
}

const HomeSearch: React.FC<Props> = ({ baseUrl, className, columns, pageSize = DEFAULT_PAGE_SIZE }) => {
  const [bedrooms, setBedrooms] = useState('all')
  const [bathrooms, setBathrooms] = useState('all')
  const [lotSize, setLotSize] = useState('all')
  const [lastSoldDate, setLastSoldDate] = useState('all')
  const [offset, setOffset] = useState(0)

  const [loading, setLoading] = useState(true)
  const [pagesCount, setPagesCount] = useState<number | null>(null)
  const [more, setMore] = useState<boolean | null>(null)
  const [results, setResults] = useState<Array<HomeSearchResultItem>>([])

  const componentRef = useRef<HTMLDivElement | null>(null)
  const [visible, setVisible] = useState(false)

  const handleBedroomsChange = useCallback((e) => {
    setBedrooms(e.target.value)
    setOffset(0)
  }, [])

  const handleBathroomsChange = useCallback((e) => {
    setBathrooms(e.target.value)
    setOffset(0)
  }, [])

  const handleLotSizeChange = useCallback((e) => {
    setLotSize(e.target.value)
    setOffset(0)
  }, [])

  const handleLastSoldDateChange = useCallback((e) => {
    setLastSoldDate(e.target.value)
    setOffset(0)
  }, [])

  const handleOffsetChange = useCallback(
    (offset) => () => {
      setOffset(offset)
    },
    []
  )

  useEffect(() => {
    if (visible) return
    if (!componentRef || !componentRef.current) return

    const target = componentRef.current

    const observer = new IntersectionObserver(
      (entries: Array<IntersectionObserverEntry>) => {
        if (entries.length > 0) {
          setVisible(entries[0].isIntersecting)
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      }
    )

    observer.observe(target)

    return () => {
      if (observer && target) {
        observer.unobserve(target)
      }
    }
  }, [visible])

  useEffect(() => {
    // Don't load if we're not visible
    if (!visible) return

    const loadRequests = async () => {
      setLoading(true)
      const url =
        `${baseUrl}?limit=${pageSize}&offset=${offset * pageSize}` +
        (bedrooms != 'all' ? `&bedrooms=${encodeURIComponent(bedrooms)}` : '') +
        (bathrooms != 'all' ? `&bathrooms=${encodeURIComponent(bathrooms)}` : '') +
        (lotSize != 'all' ? `&lotsize=${encodeURIComponent(lotSize)}` : '') +
        (lastSoldDate != 'all' ? `&lastsolddate=${encodeURIComponent(lastSoldDate)}` : '')

      const response = await fetch(url, {
        headers: { Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
      })
      const body = await response.json()

      if (body.count != null) {
        setPagesCount(Math.ceil(body.count / pageSize))
        setMore(null)
      } else {
        setPagesCount(null)
        setMore(body.more)
      }

      const results = body.tax_assessors
      setResults(results)
      setLoading(false)
    }
    loadRequests()
  }, [visible, baseUrl, bedrooms, bathrooms, lotSize, lastSoldDate, offset, pageSize])

  const enableNext = useMemo(() => {
    if (more) return true
    if (pagesCount && offset < pagesCount - 1) return true

    return false
  }, [more, pagesCount, offset])

  const pages = useMemo(() => {
    if (!pagesCount) return []

    let pageStart = Math.max(0, offset - Math.floor(MAX_SHOW_PAGES / 2))
    let pageEnd = pageStart + MAX_SHOW_PAGES
    if (pageEnd >= pagesCount) {
      const shift = pageEnd - pagesCount
      pageStart = Math.max(0, pageStart - shift)
      pageEnd = pageEnd - shift
    }
    const pages: Array<number> = []
    for (let x = pageStart; x < pageEnd; x++) {
      pages.push(x)
    }
    return pages
  }, [pagesCount, offset])

  return (
    <div className={cx(styles.homeSearch, className)} ref={componentRef}>
      <ul className={styles.options}>
        <li>
          <label htmlFor="ddlBedrooms">{'Bedrooms'}</label>
          <div className={styles.selectWrapper}>
            <select id="ddlBedrooms" onChange={handleBedroomsChange}>
              <option value="all">{'All'}</option>
              <option value="1">{'1'}</option>
              <option value="2">{'2'}</option>
              <option value="3">{'3'}</option>
              <option value="4">{'4'}</option>
              <option value="5">{'5'}</option>
              <option value="6:">{'6+'}</option>
            </select>
            <DropdownArrow className={styles.arrow} />
          </div>
        </li>
        <li>
          <label htmlFor="ddlBathrooms">{'Bathrooms'}</label>
          <div className={styles.selectWrapper}>
            <select id="ddlBathrooms" onChange={handleBathroomsChange}>
              <option value="all">{'All'}</option>
              <option value="1">{'1'}</option>
              <option value="2">{'2'}</option>
              <option value="3">{'3'}</option>
              <option value="4">{'4'}</option>
              <option value="5">{'5'}</option>
              <option value="6:">{'6+'}</option>
            </select>
            <DropdownArrow className={styles.arrow} />
          </div>
        </li>
        <li>
          <label htmlFor="dllLotSize">{'Lot size'}</label>
          <div className={styles.selectWrapper}>
            <select id="dllLotSize" onChange={handleLotSizeChange}>
              <option value="all">{'All'}</option>
              <option value=":2000">{'0 - 2000 sqft'}</option>
              <option value="2001:3000">{'2001 - 3000 sqft'}</option>
              <option value="3001:4000">{'3001 - 4000 sqft'}</option>
              <option value="4001:5000">{'4001 - 5000 sqft'}</option>
              <option value="5001:10000">{'5001 - 10000 sqft'}</option>
              <option value="10001:15000">{'10001 - 15000 sqft'}</option>
              <option value="15001:">{'15001+ sqft'}</option>
            </select>
            <DropdownArrow className={styles.arrow} />
          </div>
        </li>
        <li>
          <label htmlFor="ddllastSoldDate">{'Last sold year'}</label>
          <div className={styles.selectWrapper}>
            <select id="ddllastSoldDate" onChange={handleLastSoldDateChange}>
              <option value="all">{'All'}</option>
              {Array.from(Array(MAX_SOLD_YEARS_TO_SHOW), (_, i) => {
                const year = new Date().getUTCFullYear() - i
                return (
                  <option key={year} value={`${year}-01-01:${year}-12-31`}>
                    {year}
                  </option>
                )
              })}
              <option value={`:${new Date().getUTCFullYear() - MAX_SOLD_YEARS_TO_SHOW + 1}-01-01`}>{`< ${
                new Date().getUTCFullYear() - MAX_SOLD_YEARS_TO_SHOW + 1
              }`}</option>
            </select>
            <DropdownArrow className={styles.arrow} />
          </div>
        </li>
      </ul>
      <div className={styles.results}>
        <HomeSearchListings results={results} columns={columns} loading={loading} />
        <div className={styles.pagination}>
          <button
            type="button"
            className={styles.paginationButton}
            disabled={offset == 0}
            onClick={handleOffsetChange(Math.max(0, offset - 1))}
          >
            {'Previous'}
          </button>
          <div className={styles.pageLinks}>
            {pages.map((page) => (
              <button
                type="button"
                key={page}
                className={cx(styles.paginationButton, { [styles.pbActive]: offset == page })}
                onClick={handleOffsetChange(page)}
              >
                {page + 1}
              </button>
            ))}
          </div>
          <button
            type="button"
            className={cx(styles.paginationButton, styles.next)}
            disabled={!enableNext}
            onClick={handleOffsetChange(offset + 1)}
          >
            {'Next'}
          </button>
        </div>
        {loading ? (
          <div className={styles.loading}>
            <Sparkle />
          </div>
        ) : results.length == 0 ? (
          <div className={styles.empty}>{'No items found.'}</div>
        ) : null}
      </div>
    </div>
  )
}

export default HomeSearch
