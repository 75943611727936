import React from 'react'
import { useRecoilValue } from 'recoil'

import { publicNeighborhoodState } from 'recoil/publicNeighborhood'

import styles from './styles.module.scss'

const Intro: React.FC = () => {
  const publicNeighborhood = useRecoilValue(publicNeighborhoodState)

  return (
    <div className={styles.intro}>
      <h1>{`${publicNeighborhood?.name} Homes`}</h1>
      <p>
        {`Realm's neighborhood real estate analysis helps you understand how your home compares. We estimate the current value of single-family homes and what they could be worth after various upgrades. Uunderstand how much potential is still untapped in ${publicNeighborhood?.name}.`}
      </p>
      <a href="/how-it-works/homeowners">{'Learn more about Realm'}</a>
    </div>
  )
}

export default Intro
