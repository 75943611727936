import LivableSpace from './LivableSpace'
import React, { FC } from 'react'
import Section from 'pages/_serverRendered/PublicProperty/Section'

const ScoreAndLivableSpace: FC = () => {
  return (
    <Section>
      <LivableSpace />
    </Section>
  )
}

export default ScoreAndLivableSpace
