import cx from 'classnames'
import React, { FC } from 'react'

interface ProjectsProps {
  className?: string
}

const Projects: FC<ProjectsProps> = ({ className }) => (
  <svg
    className={cx('projects', className)}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="projects" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M21.67,18.17 L16.37,12.87 L15.38,12.87 L12.84,15.41 L12.84,16.4 L18.14,21.7 C18.53,22.09 19.16,22.09 19.55,21.7 L21.67,19.58 C22.06,19.2 22.06,18.56 21.67,18.17 Z"
        id="Path"
        fill="#000000"
        fillRule="nonzero"
      ></path>
      <path
        d="M17.34,10.19 L18.75,8.78 L20.87,10.9 C22.04,9.73 22.04,7.83 20.87,6.66 L17.33,3.12 L15.92,4.53 L15.92,1.71 L15.22,1 L11.68,4.54 L12.39,5.25 L15.22,5.25 L13.81,6.66 L14.87,7.72 L11.98,10.61 L7.85,6.48 L7.85,5.06 L4.83,2.04 L2,4.87 L5.03,7.9 L6.44,7.9 L10.57,12.03 L9.72,12.88 L7.6,12.88 L2.3,18.18 C1.91,18.57 1.91,19.2 2.3,19.59 L4.42,21.71 C4.81,22.1 5.44,22.1 5.83,21.71 L11.13,16.41 L11.13,14.29 L16.28,9.14 L17.34,10.19 Z"
        id="Path"
        fill="#000000"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)

export default Projects
