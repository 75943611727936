import ArrowCircleDown from 'svgs/arrow-circle-down'
import { formatAddressCasing, formatCurrency, formatOrdinal, formatPublicPropertyAddress2nd } from 'utils/formatting'
import { generateIndexedUrlPathForPublicProperty } from 'utils/publicProperty'
import { publicPropertyRelativeStatsState, publicPropertyState } from 'recoil/publicProperty'
import React, { FC } from 'react'
import { useRecoilValue } from 'recoil'
import Section from '../Section'

import styles from './styles.module.scss'
import { PublicPropertyAvatar } from 'components/PropertyAvatar'

const CurrentValue: FC = () => {
  const taxAssessor = useRecoilValue(publicPropertyState)
  const relativeStats = useRecoilValue(publicPropertyRelativeStatsState)

  if (!relativeStats || !relativeStats.top_value) {
    return null
  }

  return (
    <Section className={styles.card}>
      <div>
        <header className={styles.cardHeader}>
          <h4>{'How does your home value compare to your neighbors?'}</h4>
          <div className={styles.cardSubheader}>
            <b className={styles.scoreLabel}>{formatOrdinal(relativeStats.value_position || 0)}</b>
            {' out of '}
            <b>{'100'}</b>
            {' homes'}
          </div>
        </header>
      </div>
      <div className={styles.ctaContainer}>
        <a href={`/compare/${taxAssessor?.attom_id}`}>
          {'Explore other comparisons'}
          <ArrowCircleDown />
        </a>
      </div>
      {relativeStats.value_position !== 0 && (
        <div className={styles.cardHighlight}>
          <header>
            <h5>{`Top Value Nearby`}</h5>
            <div>{'1st'}</div>
          </header>
          <p>{'This comparison is based off the 100 homes nearest to you.'}</p>
          <a
            className={styles.cardHighlightHome}
            href={generateIndexedUrlPathForPublicProperty(relativeStats.top_value_tax_assessor)}
          >
            <PublicPropertyAvatar
              publicProperty={relativeStats.top_value_tax_assessor}
              width={300}
              height={200}
              className={styles.cardHighlightHomeImg}
            />
            <div>
              <div className={styles.valueLabel}>{formatCurrency(relativeStats.top_value)}</div>
              <div>{formatAddressCasing(relativeStats.top_value_tax_assessor.propertyaddressfull)}</div>
              <p>{formatPublicPropertyAddress2nd(relativeStats.top_value_tax_assessor)}</p>
            </div>
          </a>
        </div>
      )}
    </Section>
  )
}

export default CurrentValue
