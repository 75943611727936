import * as React from 'react'
import { useRecoilValue } from 'recoil'

import { publicNeighborhoodState } from 'recoil/publicNeighborhood'

import Section from '../Section'
import AverageHomeValue from './AverageHomeValue'
import AverageHomeValuePotentialIncrease from './AverageHomeValuePotentialIncrease'
import AverageBuildableSquareFeet from './AverageBuildableSquareFeet'
import AverageSalePrice from './AverageSalePrice'
import ChartContainer from '../ChartContainer'

import styles from './styles.module.scss'

const Statistics: React.FC = () => {
  const publicNeighborhood = useRecoilValue(publicNeighborhoodState)

  if (!publicNeighborhood) return null

  return (
    <Section anchorId="statistics" className={styles.container}>
      <h2 className={styles.title}>{`${publicNeighborhood.name} By The Numbers`}</h2>
      <ChartContainer className={styles.centerStatsContainer}>
        <div className={styles.centerStats}>
          <AverageHomeValue
            values={[publicNeighborhood.avm_25th, publicNeighborhood.avm_avg, publicNeighborhood.avm_75th]}
          />
          <AverageHomeValuePotentialIncrease
            values={[
              publicNeighborhood.potential_value_25th,
              publicNeighborhood.potential_value_avg,
              publicNeighborhood.potential_value_75th,
            ]}
          />
        </div>
      </ChartContainer>
      <div className={styles.footerStats}>
        <AverageBuildableSquareFeet value={publicNeighborhood.buildable_sqft_avg} />
        <AverageSalePrice value={publicNeighborhood.sale_price_avg} />
      </div>
    </Section>
  )
}
export default Statistics
