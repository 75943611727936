import React, { FC } from 'react'
import FlippingCards from 'pages/_serverRendered/CmsPage/Section/FlippingCards'
import { SliceFlippingCards } from 'pages/_serverRendered/CmsPage/types'
import { publicPropertyPrismicDataState } from 'recoil/publicProperty'
import { useRecoilValue } from 'recoil'

const Quotes: FC = () => {
  const publicPropertyPrismicData = useRecoilValue(publicPropertyPrismicDataState)
  if (!publicPropertyPrismicData) return null

  const sliceFlippingCards = publicPropertyPrismicData.body.find(
    (e) => e.slice_type === 'flipping_cards'
  ) as SliceFlippingCards

  return <FlippingCards {...sliceFlippingCards} />
}

export default Quotes
