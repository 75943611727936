import { states } from './BrowseStates'
import React, { FC } from 'react'
import { useRecoilValue } from 'recoil'
import { publicPropertyEnrichedState, publicPropertyState } from 'recoil/publicProperty'
import { formatAddressCasing, titleCase } from 'utils/formatting'

import styles from './Breadcrumb.module.scss'

const Breadcrumb: FC = () => {
  const property = useRecoilValue(publicPropertyState)
  const propertyEnriched = useRecoilValue(publicPropertyEnrichedState)

  const stateParam = property?.propertyaddressstate.toLowerCase()

  return (
    <div className={styles.breadcrumb}>
      <a href={`/${stateParam}`}>{states.find((s) => s.code === property?.propertyaddressstate)?.name}</a>
      <span className={styles.separator}>{'→'}</span>
      <a href={`/${stateParam}/${propertyEnriched?.city_param}`}>{titleCase(property?.propertyaddresscity || '')}</a>
      <span className={styles.separator}>{'→'}</span>
      <strong>{formatAddressCasing(property?.propertyaddressfull || '')}</strong>
    </div>
  )
}

export default Breadcrumb
