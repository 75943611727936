import React, { useCallback } from 'react'

import { formatLotSize, formatPublicPropertyAddress, numberWithCommas } from 'utils/formatting'

import { trackSync as track } from 'utils/analytics'

import Section from 'pages/_serverRendered/PublicProperty/Section'
import { publicPropertyEnrichedState, publicPropertyState } from 'recoil/publicProperty'
import { signUpPathWithPublicProperty } from 'utils/publicProperty'
import styles from './styles.module.scss'
import { useRecoilValue } from 'recoil'
import Bed from 'svgs/bed'
import Bath from 'svgs/bath'
import PropertyDetails from 'pages/_serverRendered/PublicProperty/PropertyDetails'
import { PublicPropertyAvatar } from 'components/PropertyAvatar'

interface HeroProps {
  ctaCopy?: string
}

const Hero: React.FC<HeroProps> = ({ ctaCopy }) => {
  const publicProperty = useRecoilValue(publicPropertyState)
  const publicPropertyEnriched = useRecoilValue(publicPropertyEnrichedState)

  const hasBedrooms = publicPropertyEnriched && publicPropertyEnriched.bedrooms > 0
  const hasBathrooms = publicPropertyEnriched && publicPropertyEnriched.total_bathrooms > 0

  const hasBuildingSquareFeet = publicPropertyEnriched && publicPropertyEnriched.square_footage != null
  const buildingSquareFeet = hasBuildingSquareFeet
    ? numberWithCommas(publicPropertyEnriched?.square_footage || 0)
    : null

  const handleCtaClick = useCallback(() => {
    track('hero')
  }, [])

  const handleExploreCtaClick = useCallback(() => {
    track('explore')
  }, [])

  if (!publicProperty) return null

  // Mapbox static image API doesn't support searching by address. We don't want to geocode ourselves because $$, but google maps supports it at no additional cost
  const hasLatLng =
    publicProperty.propertylatitude != null &&
    publicProperty.propertylatitude != '' &&
    publicProperty.propertylongitude != null &&
    publicProperty.propertylongitude != ''
  const mapboxUrl = `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/${publicProperty.propertylongitude},${publicProperty.propertylatitude},19,0/1000x350@2x?access_token=pk.eyJ1Ijoicm9od2VpZ2VsIiwiYSI6ImNrYmg1YWh2YzAyMjkyem5yN2RsMmRpZXkifQ.STA87m4W37moWj2evrjymw`
  const googleMapsUrls = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURI(
    formatPublicPropertyAddress(publicProperty)
  )}&zoom=18&scale=2&maptype=satellite&size=1000x350&key=AIzaSyDIABYI0DmyZHUfhoJxVXKDXJ_4znAEE8E`
  const mapUrl = hasLatLng ? mapboxUrl : googleMapsUrls

  const saveButton = (
    <a
      id="explore-cta"
      href={signUpPathWithPublicProperty(publicProperty)}
      className={styles.save}
      onClick={handleExploreCtaClick}
      data-hide-on="logged-in"
    >
      {ctaCopy || 'Explore'}
    </a>
  )

  return (
    <Section
      headerClassName={styles.header}
      title={formatPublicPropertyAddress(publicProperty)}
      action={saveButton}
      itemScope
      itemType="https://schema.org/SingleFamilyResidence"
    >
      <div className={styles.container}>
        <div className={styles.satellite} style={{ backgroundImage: `url(${mapUrl})` }}></div>
        <div className={styles.streetViewWrapper}>
          <PublicPropertyAvatar
            className={styles.streetViewImage}
            publicProperty={publicProperty}
            width={600}
            height={400}
          />
        </div>
        <div className={styles.statsContainer}>
          <div className={styles.propertySectionTitle}>{'Property overview'}</div>
          <div className={styles.stats}>
            {hasBedrooms && (
              <div className={styles.stat}>
                <h3>
                  <span itemProp="numberOfBedrooms">{publicPropertyEnriched?.bedrooms}</span>
                  <Bed />
                </h3>
                <div>{'Bedrooms'}</div>
              </div>
            )}
            {hasBathrooms && (
              <div className={styles.stat}>
                <h3>
                  <span itemProp="numberOfBathroomsTotal">{publicPropertyEnriched?.total_bathrooms}</span>
                  <Bath />
                </h3>
                <div>{'Bathrooms'}</div>
              </div>
            )}
            {publicProperty.yearbuilt && (
              <div className={styles.stat}>
                <h3 itemProp="yearBuilt">{publicProperty.yearbuilt}</h3>
                <div>{'Built'}</div>
              </div>
            )}
            {publicPropertyEnriched?.lot_size && (
              <div className={styles.stat}>
                <h3>{formatLotSize(publicPropertyEnriched.lot_size)}</h3>
                <div>{'Lot'}</div>
              </div>
            )}
            {buildingSquareFeet && (
              <div className={styles.stat}>
                <h3>
                  <span itemProp="floorSize">{buildingSquareFeet}</span>
                  <span>{' sqft'}</span>
                </h3>
                <div>{'Area'}</div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.edit}>
          <a
            id="heroCta"
            href={signUpPathWithPublicProperty(publicProperty)}
            onClick={handleCtaClick}
            data-hide-on="logged-in"
          >
            {'Something look incorrect? Edit Property Details'}
          </a>
        </div>
      </div>
      <PropertyDetails />
    </Section>
  )
}
export default Hero
