import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import classNames from 'classnames'

import { publicNeighborhoodState } from 'recoil/publicNeighborhood'

import styles from './styles.module.scss'

const TARGETS = [
  { id: 'overview', ratio: 0.5 },
  { id: 'statistics', ratio: 0.3 },
  { id: 'projects', ratio: 0.5 },
  { id: 'nearbyHomes', ratio: 0.1 },
]

interface Props {
  className?: string
}

const Menu: React.FC<Props> = ({ className }) => {
  const publicNeighborhood = useRecoilValue(publicNeighborhoodState)
  const [selectedIds, setSelectedIds] = useState<{ [id: string]: boolean }>(
    TARGETS.reduce((prev, target) => ({ ...prev, [target.id]: false }), {} as { [id: string]: boolean })
  )

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: Array<IntersectionObserverEntry>) => {
        // Check each entry against a target. if the intersectionRatio is above the
        // desired target ratio, it should be selected. Otherwise, it shouldn't be.
        const newSelectedIds = entries.reduce((prev, e) => {
          const target = TARGETS.find((t) => t.id == e.target.id)
          if (!target) return prev
          return { ...prev, [target.id]: target.ratio <= e.intersectionRatio }
        }, {} as { [id: string]: boolean })
        setSelectedIds((prev) => ({ ...prev, ...newSelectedIds }))
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: TARGETS.map((t) => t.ratio),
      }
    )

    TARGETS.forEach((t) => {
      const element = document.getElementById(t.id)
      if (element) {
        observer.observe(element)
      }
    })

    return () => {
      TARGETS.forEach((t) => {
        const element = document.getElementById(t.id)
        if (element) {
          observer.unobserve(element)
        }
      })
    }
  }, [])

  return (
    <div className={classNames(className, styles.menu)}>
      <ul>
        <li>
          <a id="overviewLink" href="#overview_point">
            <span className={classNames(styles.item, { [styles.selected]: selectedIds['overview'] })}>
              <span className={styles.arrow}>{'→'}</span>
              <span className={styles.text}>{publicNeighborhood?.name}</span>
            </span>
          </a>
        </li>
        <li>
          <a id="statisticsLink" href="#statistics_point">
            <span className={classNames(styles.item, { [styles.selected]: selectedIds['statistics'] })}>
              <span className={styles.arrow}>{'→'}</span>
              <span className={styles.text}>{'By The Numbers'}</span>
            </span>
          </a>
        </li>
        <li>
          <a id="projectsLink" href="#projects_point">
            <span className={classNames(styles.item, { [styles.selected]: selectedIds['projects'] })}>
              <span className={styles.arrow}>{'→'}</span>
              <span className={styles.text}>{'Popular Projects'}</span>
            </span>
          </a>
        </li>
        <li>
          <a id="nearbyHomesLink" href="#nearbyHomes_point">
            <span className={classNames(styles.item, { [styles.selected]: selectedIds['nearbyHomes'] })}>
              <span className={styles.arrow}>{'→'}</span>
              <span className={styles.text}>{'Nearby Homes'}</span>
            </span>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Menu
