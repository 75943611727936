import * as React from 'react'
import { useRecoilValue } from 'recoil'

import ImgPlant from 'images/gm/plan.png'

import { publicNeighborhoodState } from 'recoil/publicNeighborhood'

interface MapStyle {
  feature?: string
  element: string
  color?: string
  invert_lightness?: boolean
}

function formatStylesUrlPart(styles: Array<MapStyle>): string {
  return styles
    .map((style) => {
      let ret = `&style=element:${style.element}`
      if (style.feature) ret += `|feature:${style.feature}`
      if (style.color) ret += `|color:${style.color}`
      if (typeof style.invert_lightness === 'boolean') ret += `|invert_lightness:${style.invert_lightness}`
      return ret
    })
    .join('')
}

interface Marker {
  icon?: string
  color?: string
  label?: string
  anchor?: 'top' | 'bottom' | 'left' | 'right' | 'center' | 'topleft' | 'topright' | 'bottomleft' | 'bottomright'
  locations: Array<{
    long: number
    lat: number
  }>
  size?: 'tiny' | 'mid' | 'small'
  scale?: 1 | 2 | 4
}

function formatPublicURL(path: string): string {
  // If it is relative, add the host. Otherwise
  // Assume it is on a CDN, and don't.
  if (path.startsWith('https://') || path.startsWith('http://')) return path

  return `${process.env.HOST_ROOT}${path}`
}

function formatMarkersUrlPart(markers: Array<Marker>): string {
  return markers
    .map((marker) => {
      let ret = ''
      if (marker.icon) ret += `${ret ? '|' : ''}icon:${marker.icon}`
      if (marker.color) ret += `${ret ? '|' : ''}color:${marker.color}`
      if (marker.label) ret += `${ret ? '|' : ''}label:${marker.label}`
      if (marker.anchor) ret += `${ret ? '|' : ''}anchor:${marker.anchor}`
      if (marker.size) ret += `${ret ? '|' : ''}size:${marker.size}`
      if (marker.scale) ret += `${ret ? '|' : ''}scale:${marker.scale}`
      // Add locations last.
      ret += '|' + marker.locations.map((loc) => `${loc.lat},${loc.long}`).join('|')
      return `&markers=${ret}`
    })
    .join('')
}

interface Props {
  className?: string
}

const ProjectsMap: React.FC<Props> = ({ className }) => {
  const publicNeighborhood = useRecoilValue(publicNeighborhoodState)

  const mapStyles: Array<MapStyle> = [
    {
      feature: 'road',
      element: 'geometry.fill',
      color: '0xffffff',
    },
    {
      feature: 'road',
      element: 'geometry.stroke',
      color: '0xe9e1cc',
    },
    {
      feature: 'road.local',
      element: 'geometry.fill',
      color: '0xf7f4ec',
    },
    {
      feature: 'road.local',
      element: 'geometry.stroke',
      color: '0xe9e1cc',
    },
    {
      feature: 'landscape',
      element: 'geometry.fill',
      color: '0xe9e1cc',
    },
    {
      feature: 'landscape.natural.landcover',
      element: 'geometry.fill',
      color: '0xcbdc9c',
    },
    {
      feature: 'administrative',
      element: 'labels.text.fill',
      color: '0x505050',
    },
    {
      feature: 'poi',
      element: 'geometry.fill',
      color: '0xdcd0af',
    },
    {
      feature: 'poi.park',
      element: 'geometry.fill',
      color: '0xcbdc9c',
    },
    {
      feature: 'road',
      element: 'labels.text.fill',
      color: '0x9e8953',
    },
    {
      feature: 'poi',
      element: 'labels.text.fill',
      color: '0xa7602e',
    },
    {
      feature: 'poi',
      element: 'labels.icon',
      color: '0xa7602e',
    },
  ]

  if (!publicNeighborhood) return null

  const markerStyles: Array<Marker> = publicNeighborhood.top_projects.map((proj) => ({
    icon: formatPublicURL(proj.icon_marker_url || ImgPlant),
    scale: 2,
    locations: [{ lat: proj.latitude, long: proj.longitude }],
  }))
  const mapUrl =
    `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURI(
      `${publicNeighborhood.geo.center_latitude},${publicNeighborhood.geo.center_longitude}`
    )}${encodeURI(formatStylesUrlPart(mapStyles))}${encodeURI(formatMarkersUrlPart(markerStyles))}` +
    `&visible=${publicNeighborhood.geo.min_latitude},${publicNeighborhood.geo.min_longitude}|${publicNeighborhood.geo.max_latitude},${publicNeighborhood?.geo.max_longitude}` +
    `&maptype=roadmap&scale=2&size=512x200&key=${process.env.GOOGLE_MAPS_API_KEY}`

  return <div className={className} style={{ backgroundImage: `url(${mapUrl})` }} />
}
export default ProjectsMap
