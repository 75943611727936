import 'stylesheets/application.scss'
import 'utils/sentry'

import ReactOnRails from 'react-on-rails'

import BlogHome from 'pages/_serverRendered/Blog/Home'
import BlogPost from 'pages/_serverRendered/Blog/BlogPost'
import CmsPage from 'pages/_serverRendered/CmsPage'
import CmsPageV2 from 'pages/_serverRendered/CmsPageV2'
import ContractorsNearMe from 'pages/_serverRendered/landing/ContractorsNearMe'
import Homepage from 'pages/_serverRendered/Homepage'
import HomepageV2212 from 'pages/_serverRendered/HomepageV2212'
import HomeSearch from 'pages/_serverRendered/HomeSearch'
import HomeValuesNearMe from 'pages/_serverRendered/landing/HomeValuesNearMe'
import Learn from 'pages/_serverRendered/Learn'
import Meet from 'pages/_serverRendered/Meet'
import MobileAppPage from 'pages/_serverRendered/MobileAppPage'
import Privacy from 'pages/_serverRendered/Privacy'
import ProjectCaseStudy from 'pages/_serverRendered/ProjectCaseStudy'
import ProjectPricing from 'pages/_serverRendered/ProjectPricing'
import PropertyPotential from 'pages/_serverRendered/landing/PropertyPotential'
import PublicCity from 'pages/_serverRendered/Public/City'
import PublicNeighborhood from 'pages/_serverRendered/PublicNeighborhood'
import PublicProperty from 'pages/_serverRendered/PublicProperty'
import PublicState from 'pages/_serverRendered/Public/State'
import RateTable from 'pages/_serverRendered/RateTable'
import Terms from 'pages/_serverRendered/Terms'
import Unavailable from 'pages/_serverRendered/Unavailable'
import VendorContract from 'pages/_serverRendered/VendorContract'
import VendorSignup from 'pages/_serverRendered/VendorSignup'
import EventSignup from 'pages/_serverRendered/EventSignup'
import AeEventSignup from 'pages/_serverRendered/AeEventSignup'
import ZoningApiDemo from 'pages/_serverRendered/ZoningApiDemo'
import VendorSiteVisit from 'pages/_serverRendered/VendorSiteVisit'

ReactOnRails.register({
  BlogHome,
  BlogPost,
  CmsPage,
  CmsPageV2,
  ContractorsNearMe,
  Homepage,
  HomepageV2212,
  HomeSearch,
  HomeValuesNearMe,
  Learn,
  Meet,
  MobileAppPage,
  Privacy,
  ProjectCaseStudy,
  ProjectPricing,
  PropertyPotential,
  PublicCity,
  PublicNeighborhood,
  PublicProperty,
  PublicState,
  RateTable,
  Terms,
  Unavailable,
  VendorContract,
  VendorSignup,
  ZoningApiDemo,
  VendorSiteVisit,
  EventSignup,
  AeEventSignup,
})

const checkLoggedIn = async () => {
  const response = await fetch('/api/v1/users/me', {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  if (response.status >= 200 && response.status < 300) {
    document.querySelectorAll<HTMLElement>('[data-hide-on="logged-in"]').forEach((e) => {
      if (e.style.display == 'none') return
      e.dataset.display = e.style.display
      e.style.display = 'none'
    })
    document.querySelectorAll<HTMLElement>('[data-hide-on="logged-out"]').forEach((e) => {
      if (e.style.display != 'none') return
      e.style.display = e.dataset.display || 'initial'
    })
  }
}

checkLoggedIn()
