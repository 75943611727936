import cx from 'classnames'
import React, { FC, useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { publicPropertyState, publicPropertyEnrichedState } from 'recoil/publicProperty'
import { formatNumber, numberWithCommas } from 'utils/formatting'
import styles from './styles.module.scss'
import distance from '@turf/distance'
import Property from 'svgs/property'
import Projects from 'svgs/projects'
import Neighborhood from 'svgs/neighborhood'
import NearbyProjects from 'svgs/nearby-projects'
import { track } from 'utils/analytics'
import { signUpPathWithPublicProperty } from 'utils/publicProperty'

const renderedDistanceFromPoints = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const distanceInMiles = distance(
    {
      type: 'Point',
      coordinates: [lat1, lon1],
    },
    {
      type: 'Point',
      coordinates: [lat2, lon2],
    },
    {
      units: 'miles',
    }
  )
  return distanceInMiles >= 0.3
    ? `${numberWithCommas(Math.round(distanceInMiles * 10) / 10)} mi`
    : `${numberWithCommas(Math.round(distanceInMiles * 5280))}` + ' ft'
}

const renderedDistanceFromFeet = (distance: number) =>
  distance >= 5280 * 0.3
    ? `${numberWithCommas(Math.round((distance / 5280) * 10) / 10)} mi`
    : `${numberWithCommas(Math.round(distance))} ft`

export const renderedDate = (date: string): string => {
  return new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric', timeZone: 'UTC' }).format(new Date(date))
}

const PropertyDetails: FC = () => {
  const publicProperty = useRecoilValue(publicPropertyState)
  const publicPropertyEnriched = useRecoilValue(publicPropertyEnrichedState)
  const handleUnlockProjects = useCallback(() => {
    track('unlockProjects')
  }, [])

  if (!publicProperty) return null

  return (
    <div className={styles.propertyDetails}>
      <table>
        <caption>
          <Property />
          {`Property details`}
        </caption>
        <tbody>
          {publicPropertyEnriched?.pool_area != null && (
            <tr>
              <td>{'Pool area'}</td>
              <td>{`${publicPropertyEnriched?.pool_area} sqft`}</td>
            </tr>
          )}
          {publicPropertyEnriched?.elevation_minimum != null && (
            <tr>
              <td>{'Elevation minimum'}</td>
              <td>{`${formatNumber(publicPropertyEnriched?.elevation_minimum)} ft`}</td>
            </tr>
          )}
          {publicPropertyEnriched?.elevation_maximum != null && (
            <tr>
              <td>{'Elevation maximum'}</td>
              <td>{`${formatNumber(publicPropertyEnriched?.elevation_maximum)} ft`}</td>
            </tr>
          )}
          {publicPropertyEnriched?.flood_zone != null &&
            publicPropertyEnriched?.flood_zone.toLowerCase() !== 'not applicable' && (
              <tr>
                <td>{'Is my home in a flood zone?'}</td>
                <td>{publicPropertyEnriched?.flood_zone}</td>
              </tr>
            )}
          {publicPropertyEnriched?.mowable_area != null && (
            <tr>
              <td>{'Mowable area'}</td>
              <td>{`${publicPropertyEnriched?.mowable_area} sqft`}</td>
            </tr>
          )}
          {publicPropertyEnriched?.building_area != null && (
            <tr>
              <td>{'Building area'}</td>
              <td>
                {publicPropertyEnriched?.building_area}
                {' sqft'}
              </td>
            </tr>
          )}
          {publicPropertyEnriched?.footprint_slope_average != null && (
            <tr>
              <td>{'Building footprint slope average'}</td>
              <td>{publicPropertyEnriched?.footprint_slope_average}</td>
            </tr>
          )}
          {publicPropertyEnriched?.footprint_slope_maximum != null && (
            <tr>
              <td>{'Building footprint slope maximum'}</td>
              <td>{publicPropertyEnriched?.footprint_slope_maximum}</td>
            </tr>
          )}
        </tbody>
      </table>
      <table>
        <caption>
          <Neighborhood />
          {'What’s nearby'}
        </caption>
        <tbody>
          {publicPropertyEnriched?.park_distance != null && (
            <tr>
              <td>{'Nearest park'}</td>
              <td>{renderedDistanceFromFeet(publicPropertyEnriched?.park_distance)}</td>
            </tr>
          )}
          {publicPropertyEnriched?.ocean_distance != null && (
            <tr>
              <td>{'Distance to ocean'}</td>
              <td>{renderedDistanceFromFeet(publicPropertyEnriched?.ocean_distance)}</td>
            </tr>
          )}
          {publicPropertyEnriched?.private_school_distance != null && (
            <tr>
              <td>{'Distance to private school'}</td>
              <td>{renderedDistanceFromFeet(publicPropertyEnriched?.private_school_distance)}</td>
            </tr>
          )}
          {publicPropertyEnriched?.water_body_distance != null && (
            <tr>
              <td>{'Distance to nearest water body'}</td>
              <td>{renderedDistanceFromFeet(publicPropertyEnriched?.water_body_distance)}</td>
            </tr>
          )}
          {publicPropertyEnriched?.road_distance != null && (
            <tr>
              <td>{'Distance to the road'}</td>
              <td>{renderedDistanceFromFeet(publicPropertyEnriched?.road_distance)}</td>
            </tr>
          )}
          <tr>
            <td>{'Adjacent to railway'}</td>
            <td>{publicPropertyEnriched?.railway_adjacent === 't' ? 'Yes' : 'No'}</td>
          </tr>
        </tbody>
      </table>
      <table className={styles.fullWidth}>
        <caption>
          <Projects />
          {'Projects for you'}
        </caption>
        <thead>
          <tr>
            <th>{'Project'}</th>
            <th>{'Home value'}</th>
            <th>{'Cost'}</th>
            <th>{'Recoup %'}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.signupLink} colSpan={4}>
              <a
                className={cx('unlock-projects', styles.ctaButton, styles.unlockProjectsTy)}
                href={signUpPathWithPublicProperty(publicProperty)}
                onClick={handleUnlockProjects}
                data-hide-on="logged-in"
              >
                {'Unlock with a free account'}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      {(publicPropertyEnriched?.nearby_projects?.length ?? 0) > 0 && (
        <>
          <table className={styles.fullWidth}>
            <caption>
              <NearbyProjects />
              {'Projects'}
            </caption>
            <thead>
              <tr>
                <th>{'Address'}</th>
                <th>{'Recent project'}</th>
                <th>{'Date'}</th>
                <th>{'Distance'}</th>
              </tr>
            </thead>
            <tbody>
              {publicPropertyEnriched?.nearby_projects.map((home) => {
                return (
                  <tr key={home.address}>
                    <td>{home.address}</td>
                    <td>{home.project.name}</td>
                    <td>{renderedDate(home.project.date)}</td>
                    <td>
                      {renderedDistanceFromPoints(
                        Number(publicProperty.propertylatitude),
                        Number(publicProperty.propertylongitude),
                        home.latitude,
                        home.longitude
                      )}
                      {' away'}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  )
}

export default PropertyDetails
