import React, { FC } from 'react'
import { useRecoilValue } from 'recoil'

import { publicNeighborhoodState } from 'recoil/publicNeighborhood'

import Section from '../Section'
import HomeSearch from 'pages/_serverRendered/HomeSearch'

import styles from './styles.module.scss'

const TopScoringHomes: FC = () => {
  const publicNeighborhood = useRecoilValue(publicNeighborhoodState)

  return (
    <Section anchorId="nearbyHomes" className={styles.topScoringHomes}>
      <h2 className={styles.title}>{`Browse Nearby Homes With Potential In ${publicNeighborhood?.name}`}</h2>
      <HomeSearch baseUrl={`/neighborhoods/${publicNeighborhood?.id}/properties`} />
    </Section>
  )
}

export default TopScoringHomes
