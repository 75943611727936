import classnames from 'classnames'
import React, { FC } from 'react'

import styles from './styles.module.scss'

interface Props {
  action?: React.ReactNode
  children: React.ReactNode
  className?: string
  headerClassName?: string
  title?: string
  subtitle?: string
  itemScope?: boolean
  itemType?: string
}

const Section: FC<Props> = ({ action, children, className, headerClassName, itemScope, itemType, title, subtitle }) => (
  <section className={classnames(className, styles.section)} itemScope={itemScope} itemType={itemType}>
    {(action || title) && (
      <div className={classnames(headerClassName, styles.sectionHeader)}>
        <div className={styles.titleRow}>
          {title != null && (
            <h2
              className={styles.title}
              itemProp={itemType === 'https://schema.org/SingleFamilyResidence' ? 'address' : undefined}
            >
              {title}
            </h2>
          )}
          <div className={styles.action}>{action}</div>
        </div>
        {subtitle != null && <h3 className={styles.subtitle}>{subtitle}</h3>}
      </div>
    )}
    {children}
  </section>
)

export default Section
