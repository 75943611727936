import * as React from 'react'
import { useRecoilValue } from 'recoil'

import { formatNumber, formatPercentage } from 'utils/formatting'

import Section from '../Section'
import { publicNeighborhoodState } from 'recoil/publicNeighborhood'
import styles from './styles.module.scss'

const Overview: React.FC = () => {
  const publicNeighborhood = useRecoilValue(publicNeighborhoodState)

  if (!publicNeighborhood) return null

  const mapUrl = `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/[${publicNeighborhood.geo.min_longitude},${publicNeighborhood.geo.min_latitude},${publicNeighborhood.geo.max_longitude},${publicNeighborhood.geo.max_latitude}]/1000x350@2x?access_token=${process.env.MAPBOXGL_ACCESS_TOKEN}`

  return (
    <Section anchorId="overview" className={styles.container}>
      <div className={styles.satellite} style={{ backgroundImage: `url(${mapUrl})` }}></div>
      <h2 className={styles.title}>{`${publicNeighborhood.name}, ${publicNeighborhood.statename}`}</h2>
      <ul className={styles.stats}>
        <li className={styles.stat}>
          <div className={styles.value}>{formatNumber(publicNeighborhood.total_properties)}</div>
          <div className={styles.description}>{'Homes in the neighborhood'}</div>
        </li>
        <li className={styles.stat}>
          <div className={styles.value}>{formatPercentage(publicNeighborhood.percent_for_sale * 100, 1)}</div>
          <div className={styles.description}>{'Homes currently for sale'}</div>
        </li>
        <li className={styles.stat}>
          <div className={styles.value}>{formatPercentage(publicNeighborhood.percent_sold_last_year * 100, 1)}</div>
          <div className={styles.description}>{'Homes sold in the last 12 months'}</div>
        </li>
      </ul>
    </Section>
  )
}
export default Overview
