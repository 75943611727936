import { publicPropertyBuildableAreaState, publicPropertyEnrichedState } from 'recoil/publicProperty'
import React, { FC } from 'react'
import { useRecoilValue } from 'recoil'

import styles from './styles.module.scss'

const LivableSpace: FC = () => {
  const publicProperyBuildableArea = useRecoilValue(publicPropertyBuildableAreaState)
  const publicPropertyEnriched = useRecoilValue(publicPropertyEnrichedState)

  let currentAreaValue: number | null = null
  if (publicPropertyEnriched && publicPropertyEnriched.square_footage != null) {
    currentAreaValue = publicPropertyEnriched.square_footage
  }

  let potentialAreaValue: number | null = null
  if (
    publicProperyBuildableArea != null &&
    publicProperyBuildableArea.remaining_square_footage != null &&
    publicProperyBuildableArea.remaining_square_footage > 0
  ) {
    potentialAreaValue = publicProperyBuildableArea.remaining_square_footage
  }

  let currentPercent = 0.65
  if (currentAreaValue != null && potentialAreaValue != null) {
    currentPercent = currentAreaValue / (currentAreaValue + potentialAreaValue)
    // don't show more than 100% full
    currentPercent = currentPercent > 1 ? 1 : currentPercent
  }

  return (
    <div className={styles.livableSpace}>
      <h3 className={styles.title}>{'Livable Space'}</h3>
      <div className={styles.container}>
        {potentialAreaValue && (
          <p>
            <span>{'This property is zoned for '}</span>
            <b>{potentialAreaValue.toLocaleString()}</b>
            <span>{' additional square feet'}</span>
          </p>
        )}
        <div className={styles.chart}>
          <div className={styles.potentialArea}>
            <div className={styles.currentArea} style={{ width: `${currentPercent * 100}%` }}></div>
            {currentAreaValue && (
              <div className={styles.currentAreaLabel}>
                <span className={styles.amount}>{currentAreaValue.toLocaleString()}</span>
                {'sqft'}
              </div>
            )}
            {currentAreaValue && potentialAreaValue && (
              <div className={styles.potentialAreaLabel}>
                <span className={styles.amount}>{(currentAreaValue + potentialAreaValue).toLocaleString()}</span>
                {'sqft'}
              </div>
            )}
          </div>
        </div>
        <h3 className={styles.fakeAccordionTitle}>{'Learn More About Buildable Square Feet'}</h3>
        <p>
          {
            'It’s not always up to you where and what you can build on your property. Realm has collected information on your zoning rules to calculate the number of buildable square feet available on your property given zoning rules such as setbacks, floor area ratio (FAR), and lot coverage.'
          }
        </p>
      </div>
    </div>
  )
}

export default LivableSpace
