import * as React from 'react'
import { useRecoilValue } from 'recoil'

import { publicNeighborhoodState } from 'recoil/publicNeighborhood'

import Section from '../Section'
import ProjectsMap from './ProjectsMap'
import ProjectsList from './ProjectsList'

import styles from './styles.module.scss'

const TopProjects: React.FC = () => {
  const publicNeighborhood = useRecoilValue(publicNeighborhoodState)

  return (
    <Section anchorId="projects" className={styles.container}>
      <ProjectsMap className={styles.map} />
      <div className={styles.information}>
        <h2 className={styles.title}>{`Popular Projects in ${publicNeighborhood?.name}`}</h2>
        <p className={styles.note}>
          {
            "Our pricing estimates use local labor & material costs. With your free Realm account, you can customize pricing based on square footage and quality of materials. We'll also calculate specific cost recoup estimates for your property."
          }
        </p>
        <ProjectsList className={styles.list} />
      </div>
    </Section>
  )
}
export default TopProjects
