import { Slice } from 'pages/_serverRendered/CmsPage/types'
import { RichTextBlock } from 'prismic-reactjs'
import { atom } from 'recoil'

export interface PublicPropertyPotential {
  potential_value?: number
}

export interface BuildableArea {
  remaining_square_footage: number | null
  allowed_floors: Array<number> | null
}

export interface PublicPropertyComparable {
  tax_assessor: AttomTaxAssessor
  avm: AttomAvm
  bedrooms: number
  bathrooms: number
  square_footage: number
}

export interface PublicPropertyEnriched {
  bedrooms: number
  bathrooms: number
  half_bathrooms: number
  total_bathrooms: number
  square_footage: number
  lot_size: number
  city_param: string

  building_area: number
  elevation_maximum: number
  elevation_minimum: number
  flood_zone: string
  footprint_slope_average: number
  footprint_slope_maximum: number
  mowable_area: number
  ocean_distance: number
  park_distance: number
  pool_area: number
  private_school_distance: number
  railway_adjacent: string
  road_distance: number
  water_body_distance: number

  nearby_projects: {
    address: string
    attom_id: string
    latitude: number
    longitude: number
    project: {
      name: string
      date: string
    }
  }[]
}

export interface PublicPropertyPrismicData {
  title: RichTextBlock[]
  description: RichTextBlock[]
  body: Slice[]
}

export interface PublicPropertyLightRelativeStats {
  top_value: number | null
  top_value_tax_assessor: AttomTaxAssessor
  value_position: number | null
}

export interface PublicPropertyFullRelativeStats {
  potential_position: number | null
  top_potential: number | null
  top_potential_attom_id: number | null
  top_potential_tax_assessor: AttomTaxAssessor
  top_value: number | null
  top_value_attom_id: number | null
  top_value_tax_assessor: AttomTaxAssessor
  value_position: number | null
}

export const publicPropertyState = atom<AttomTaxAssessor | null>({
  key: 'PublicProperty',
  default: null,
})

export const publicPropertyEnrichedState = atom<PublicPropertyEnriched | null>({
  key: 'PublicPropertyEnriched',
  default: null,
})

export const publicPropertyAvmState = atom<AttomAvm | null>({
  key: 'PublicPropertyAvm',
  default: null,
})

export const publicPropertyPotentialState = atom<PublicPropertyPotential | null>({
  key: 'PublicPropertyPotential',
  default: null,
})

export const publicPropertyPrismicDataState = atom<PublicPropertyPrismicData | null>({
  key: 'publicPropertyPrismicData',
  default: null,
})

export const publicPropertyBuildableAreaState = atom<BuildableArea | null>({
  key: 'PublicPropertyBuildableArea',
  default: null,
})

export const publicPropertyRelativeStatsState = atom<PublicPropertyLightRelativeStats | null>({
  key: 'PublicPropertyRelativeStats',
  default: null,
})

export const publicPropertyIndexedState = atom<boolean>({
  key: 'PublicPropertyIndexed',
  default: false,
})
