import classnames from 'classnames'
import React, { FC, ReactNode } from 'react'

import ButtonCap from './ButtonCap'

import styles from './styles.module.scss'

interface Props {
  children: ReactNode
  className?: string
  href?: string
  id?: string
  type?: 'button' | 'submit' | 'reset'
  fullWidth?: boolean
  onClick?: () => void
}

const YellowBubbleCta: FC<Props> = ({ children, className, fullWidth, id, href, type, onClick }) => {
  if (type) {
    return (
      <button
        id={id}
        type={type == 'button' ? 'button' : type == 'reset' ? 'reset' : 'submit'}
        className={classnames(className, styles.placeholderButton)}
        onClick={onClick}
      >
        <span className={styles.placeholderButtonLabel} style={{ width: fullWidth ? '100%' : 'initial' }}>
          {children}
        </span>
        <span className={styles.placeholderButtonCap}>
          <ButtonCap />
          <span className={styles.placeholderButtonArrow}>{'→'}</span>
        </span>
      </button>
    )
  }
  return (
    <a id={id} className={classnames(className, styles.placeholderButton)} href={href} onClick={onClick}>
      <span className={styles.placeholderButtonLabel} style={{ width: fullWidth ? '100%' : 'initial' }}>
        {children}
      </span>
      <span className={styles.placeholderButtonCap}>
        <ButtonCap />
        <span className={styles.placeholderButtonArrow}>{'→'}</span>
      </span>
    </a>
  )
}

export default YellowBubbleCta
