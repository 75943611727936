import cx from 'classnames'
import ImgBackyardHome from 'images/property/project-customization.png'
import ImgBackyardHome2x from 'images/property/project-customization@2x.png'
import { publicPropertyState } from 'recoil/publicProperty'
import React, { FC, useCallback } from 'react'
import Section from 'pages/_serverRendered/PublicProperty/Section'
import { signUpPathWithPublicProperty } from 'utils/publicProperty'
import { useRecoilValue } from 'recoil'
import { trackSync as track } from 'utils/analytics'

import styles from './styles.module.scss'

const ProjectCustomization: FC = () => {
  const publicProperty = useRecoilValue(publicPropertyState)

  const handleUnlockProjects = useCallback(() => {
    track('unlockProjects')
  }, [])

  if (!publicProperty) return null

  return (
    <Section className={styles.projectCustomization} title="Unlock project customization with a free account">
      <p className={styles.intro}>
        {
          'With your free Realm Project Planner, you can create customized cost & value recoup estimates for 10+ projects.'
        }
      </p>
      <img
        className={styles.image}
        width={581}
        height={522}
        alt="Preview of project customization"
        src={ImgBackyardHome}
        srcSet={`${ImgBackyardHome2x} 2x`}
      />
      <div className={styles.ctaContainer}>
        <a
          className={cx('unlock-projects', styles.ctaButton, styles.unlockProjectsTy)}
          href={signUpPathWithPublicProperty(publicProperty)}
          onClick={handleUnlockProjects}
        >
          {'Unlock projects for free'}
        </a>
        <a
          className={cx('unlock-projects', styles.ctaButton, styles.unlockProjectsSm)}
          href={signUpPathWithPublicProperty(publicProperty)}
          onClick={handleUnlockProjects}
        >
          {'Unlock projects with a free account'}
        </a>
        <a
          className={cx('unlock-projects', styles.ctaButton, styles.unlockProjectsLg)}
          href={signUpPathWithPublicProperty(publicProperty)}
          onClick={handleUnlockProjects}
          data-hide-on="logged-in"
        >
          {'Unlock more projects with a free account'}
        </a>
      </div>
    </Section>
  )
}

export default ProjectCustomization
