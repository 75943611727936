import React, { FC, useCallback } from 'react'
import YellowBubbleCta from 'components/MarketingAndPropertyPages/YellowBubbleCta'
import classnames from 'classnames'
import { publicPropertyState } from 'recoil/publicProperty'
import { signUpPathWithPublicProperty } from 'utils/publicProperty'
import styles from './styles.module.scss'
import { useRecoilValue } from 'recoil'
import { trackSync as track } from 'utils/analytics'

interface SaveThisPropertyProps {
  title?: string
}

const SaveThisProperty: FC<SaveThisPropertyProps> = ({ title }) => {
  const publicProperty = useRecoilValue(publicPropertyState)

  const handleExploreCtaClick = useCallback(() => {
    track('explore')
  }, [])

  if (!publicProperty) return null

  return (
    <div className={styles.container} data-hide-on="logged-in">
      <div className={styles.subcontainer}>
        <h2>{title || 'Never miss an opportunity'}</h2>
        <p>
          {
            'Enter an address and answer a few questions about a property to get a full breakdown of its current value and potential future value.'
          }
        </p>
      </div>
      <YellowBubbleCta
        className={classnames('explore-cta', styles.save, styles.large)}
        fullWidth
        href={signUpPathWithPublicProperty(publicProperty)}
        onClick={handleExploreCtaClick}
      >
        {'Explore this property'}
      </YellowBubbleCta>
      <YellowBubbleCta
        className={classnames('explore-cta', styles.save, styles.small)}
        fullWidth
        href={signUpPathWithPublicProperty(publicProperty)}
        onClick={handleExploreCtaClick}
      >
        {'Explore'}
      </YellowBubbleCta>
    </div>
  )
}

export default SaveThisProperty
