import cx from 'classnames'
import React, { FC } from 'react'

interface PropertyProps {
  className?: string
}

const Property: FC<PropertyProps> = ({ className }) => (
  <svg
    className={cx('property', className)}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="property" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M3.5,11.9996565 L3.5,20.5042857 L20.5,20.5042857 L20.5,11.9996565 L21.1528406,11.999806 L22.8195396,22 L1.18046042,22 L2.84715942,11.999806 L3.5,11.9996565 Z"
        id="ground"
        stroke="#000000"
        strokeWidth="2"
        fill="#000000"
      ></path>
      <polygon
        id="house"
        fill="#000000"
        fillRule="nonzero"
        points="12.1457143 4.89 18 8.79 18 18.0042857 13.7142857 18.0042857 13.7142857 11.1471429 10.2857143 11.1471429 10.2857143 18.0042857 6 18.0042857 6 8.98714286"
      ></polygon>
    </g>
  </svg>
)

export default Property
