import React from 'react'
import { useRecoilValue } from 'recoil'

import { publicPropertyState, publicPropertyAvmState } from 'recoil/publicProperty'
import { formatAddressCasing, formatCurrency } from 'utils/formatting'

import styles from './styles.module.scss'

const FAQ: React.FC = () => {
  const publicProperty = useRecoilValue(publicPropertyState)
  const publicPropertyAvm = useRecoilValue(publicPropertyAvmState)

  let estimatedCurrentValue
  if (publicPropertyAvm != null && publicPropertyAvm.estimatedvalue != null) {
    estimatedCurrentValue = formatCurrency(publicPropertyAvm.estimatedvalue)
  }

  return (
    <div className={styles.container}>
      <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
        <h3 itemProp="name">{'What is Realm and how does it estimate the value of my home?'}</h3>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            {
              'Realm is a free, centralized data hub for homeowners. We analyze hundreds of data points about each home including size, layout, quality, and amenities and compare to neighboring homes to estimate current market value. We highlight opportunities to increase property value through square-foot add-ons, renovations, new amenities, and more, helping families to enjoy their home more in the present while getting more value from it down the road.'
            }
          </div>
        </div>
      </div>
      {estimatedCurrentValue && (
        <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
          <h3 itemProp="name">{'How much is this house worth and is your estimate accurate?'}</h3>
          <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
            <div itemProp="text">
              {`Based on publicly available real estate data, Realm estimates your house is currently worth ${estimatedCurrentValue}. We will refresh our estimate if you share information on recent renovations or changes you’ve made to your home to ensure it's as accurate as possible.`}
            </div>
          </div>
        </div>
      )}
      <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
        <h3 itemProp="name">{`How does Realm estimate the potential home value for ${formatAddressCasing(
          publicProperty?.propertyaddressfull || ''
        )}?`}</h3>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            {
              'Our potential value calculation looks at your home compared to the optimal home in your neighborhood. First, we review hundreds of attributes about your home, including categorical and numeric data. Then, we analyze the attributes of high value homes in your area to determine the optimal characteristic for each attribute. Finally, we compare your house as it is to what your house would be with all the optimal attributes.'
            }
          </div>
        </div>
      </div>
      <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
        <h3 itemProp="name">{`How can I increase the home value for ${formatAddressCasing(
          publicProperty?.propertyaddressfull || ''
        )}?`}</h3>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            {
              'There are 3 types of projects that have the potential to increase your home value: increasing space, updating existing spaces, or adding amenities. With your free Realm account, you can see what projects are allowed on your property, how much each will cost, and what impact they’ll have on home value.'
            }
          </div>
        </div>
      </div>
      <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
        <h3 itemProp="name">{'How does Realm know which home projects to recommend to me?'}</h3>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            {
              'We start by reviewing the current state of your home, based on data we’ve collected from a variety of sources and input that you’ve given us. From there, we figure out what is possible given your local zoning rules & regulations and what is optimal based on what home remodeling projects are trending in your neighborhood.'
            }
          </div>
        </div>
      </div>
      <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
        <h3 itemProp="name">{`How much more space am I allowed to add to ${formatAddressCasing(
          publicProperty?.propertyaddressfull || ''
        )}?`}</h3>
        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
          <div itemProp="text">
            {
              'Each neighborhood has different types of restrictions in place that dictate how much of your lot you can develop and where you can build. We’ve reviewed setbacks, lot coverage, and FAR limits for your property to analyze how many additional square feet you can add.'
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ
